'use client'
import { cva } from 'class-variance-authority'
import { useTranslations } from 'next-intl'
import { useEffect, useRef, useState } from 'react'

import { followUserV2, unfollowUserV2 } from 'api/user'
import IconMore from 'asset/icon/general/more_horizon.svg'
import IconShare from 'asset/icon/share_f.svg'
import Headshot from 'component/block/headshot'
import LinkifyText from 'component/block/linkify-text'
import { SharePopup } from 'component/block/share/popup'
import { Button } from 'component/ui/button'
import {
  Dialog,
  DialogBackIcon,
  DialogCloseIcon,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTrigger
} from 'component/ui/dialog'
import { FOLLOW_STATUS, GA_CLICK_ACTION, HeadshotSize } from 'constant'
import { clientFetch } from 'lib/fetch/client'
import { usePopupStore } from 'store/global/popup'
import { partialUpdateUser, useUserState } from 'store/global/users'
import { useIsMobile } from 'store/server-context/device'
import { useSessionStore } from 'store/server-context/session'
import type { IAssetV2 } from 'type/asset'
import type { ValueOf } from 'type/object'
import type { IProfileCountV2 } from 'type/profile'
import filterUrls from 'util/filterUrls'
import { normalizeAsset } from 'util/normalizeAsset'
import FollowStatus from './follow-status'
import ProfileSetting from './profile-setting'

const smallHeadShotVariant = cva('', {
  variants: {
    $show: {
      true: [
        'opacity-100',
        `w-[24px]`,
        'mr-[8px]',
        '[transition:opacity_0.2s_0.1s_ease,width_0.1s_ease,margin-right_0.1s_ease]'
      ],
      false: [
        'opacity-0',
        'w-0',
        'mr-0',
        '[transition:opacity_0.2s_ease,width_0.1s_0.2s_ease,margin-right_0.1s_0.2s_ease]'
      ]
    }
  }
})

const gridAreaStyle = {
  templateAreas: {
    gridTemplateAreas: `'headshot . followers'
    'description description description'
    'share share share'`
  },
  areaHeadShot: {
    gridArea: 'headshot'
  },
  areaFollowers: {
    gridArea: 'followers'
  },
  areaDescription: {
    gridArea: 'description'
  },
  areaShare: {
    gridArea: 'share'
  }
}

type IFollowStatus = ValueOf<typeof FOLLOW_STATUS>

const getFollowTranslation = (followStatus: IFollowStatus) => {
  switch (followStatus) {
    case FOLLOW_STATUS.Followed:
      return 'common.following'
    case FOLLOW_STATUS.Requested:
      return 'common.requested'
    default:
      return 'common.follow'
  }
}

interface IProfile {
  isMe: boolean
  count: IProfileCountV2
  user: {
    user_id: string
    uid: string
    name: string
    follow_status: string
  }
  about: string
  webLink: string
  headshot: IAssetV2 | undefined | null
}

const ProfileHeader = (props: IProfile) => {
  const { isMe, user, count, headshot, about, webLink } = props

  const t = useTranslations()
  const ref = useRef<HTMLDivElement>(null)
  const [showSmallHeadShot, setShowSmallHeadShot] = useState<boolean>(false)
  const showDownloadPopup = usePopupStore(state => state.showDownloadPopup)
  const showAuthPopup = usePopupStore(state => state.showAuthPopup)
  const isMobile = useIsMobile()
  const session = useSessionStore(state => state.session)
  const freshUser = useUserState(user.user_id, {
    follow_status: user.follow_status
  })

  const buttonText = isMe
    ? t('profile.me.edit_profile')
    : t(getFollowTranslation(freshUser.follow_status as IFollowStatus))

  const handleFollowChange = async () => {
    if (session.guest) {
      showAuthPopup()
      return
    }

    let followStatus = FOLLOW_STATUS.None
    if (freshUser.follow_status === FOLLOW_STATUS.None) {
      followStatus = FOLLOW_STATUS.Followed
    }
    partialUpdateUser(user.user_id, { follow_status: followStatus })

    const followApi =
      freshUser.follow_status !== FOLLOW_STATUS.Followed
        ? followUserV2
        : unfollowUserV2

    followApi(clientFetch, {
      user_id: user.user_id
    })
  }
  const buttonAction = isMe ? showDownloadPopup : handleFollowChange

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setShowSmallHeadShot(false)
          } else {
            setShowSmallHeadShot(true)
          }
        })
      },
      {
        rootMargin: '-130px 0px 0px 0px'
      }
    )
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <>
      <div className='sticky top-[--fixed-top] z-[2] flex h-[48px] items-center justify-center bg-background-1st px-[24px] text-label-l1'>
        <div className='flex min-w-0 flex-grow items-center justify-center'>
          <Headshot
            {...normalizeAsset(headshot)}
            size={HeadshotSize.xs}
            className={smallHeadShotVariant({ $show: showSmallHeadShot })}
          />
          <span className='inline-block min-w-0 max-w-[calc(100%_-_32px)] flex-shrink text-ellipsis font-semibold'>
            {user.uid}
          </span>
        </div>
        {isMe && (
          <Dialog>
            <DialogTrigger asChild={true}>
              <Button
                $icon={true}
                $variant='text'
                $size='sm'
                className='min1080:hidden'
              >
                <IconMore width={24} height={24} />
              </Button>
            </DialogTrigger>
            <DialogPortal>
              <DialogOverlay />
              <DialogContent>
                <ProfileSetting />
                {!isMobile && <DialogCloseIcon />}
                {isMobile && <DialogBackIcon />}
              </DialogContent>
            </DialogPortal>
          </Dialog>
        )}
      </div>
      <section
        ref={ref}
        style={gridAreaStyle.templateAreas}
        className='grid w-full grid-cols-[96px_16px_1fr] p-[16px] pt-0'
      >
        {/* HeadShot */}
        <div style={gridAreaStyle.areaHeadShot}>
          <Headshot
            {...normalizeAsset(headshot, true)}
            size={HeadshotSize.xl}
            className='profile-headShot'
            gtmTag='profile-headShot'
            gaAction={GA_CLICK_ACTION.Common.headshotClickAction}
          />
        </div>

        {/* Follower */}
        <div
          className='flex w-full items-center'
          style={gridAreaStyle.areaFollowers}
        >
          <FollowStatus count={count}></FollowStatus>
        </div>

        {/* Description */}
        <div
          className='w-full max-w-full whitespace-pre-wrap break-words pt-[16px]'
          style={gridAreaStyle.areaDescription}
        >
          <div
            className='mb-[2px] max-w-[350px] text-ellipsis break-words font-semibold'
            data-ga-click={GA_CLICK_ACTION.Profile.profileNameClickAction}
          >
            {user.name}
          </div>
          {about && <div>{filterUrls(about).trim()}</div>}
          {webLink && (
            <div className='mt-[2px] max-w-[350px] overflow-hidden text-ellipsis whitespace-nowrap'>
              <LinkifyText parseHyperlink={true} text={webLink} />
            </div>
          )}
        </div>

        <div
          className='flex w-full justify-center space-x-[8px] pt-[16px]'
          style={gridAreaStyle.areaShare}
        >
          <Button
            $variant='outline'
            $size='sm'
            $radius='md'
            className='min-w-[96px] flex-shrink-0 flex-grow'
            onClick={buttonAction}
            title={buttonText}
            data-ga-click={GA_CLICK_ACTION.FollowShare.followBtnClickAction}
          >
            {buttonText}
          </Button>
          <SharePopup type='profile' shareId={user.uid}>
            <Button
              $icon={true}
              $variant='outline'
              $size='md'
              className='flex-shrink-0'
              data-ga-click={GA_CLICK_ACTION.Share.shareIconClickAction}
            >
              <IconShare />
            </Button>
          </SharePopup>
        </div>
      </section>
    </>
  )
}

export default ProfileHeader
