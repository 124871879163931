'use client'

import { useInfiniteQuery } from '@tanstack/react-query'
import NoMySpot from 'component/block/no-content/my-spot'
import NoSpot from 'component/block/no-content/spot'
import ScrollLoad from 'component/block/scrollLoad'
import SpotLink from 'component/block/spot/spot-link'
import { NO_MORE_DATA, POPUP_TRIGGER_HEIGHT } from 'constant'
import useScrollingCallback from 'hook/useScrollingCallback'
import { useLocale } from 'next-intl'
import { useInitialSpotIdsStore } from 'store/global/initial-spot-ids'
import { usePopupStore } from 'store/global/popup'
import { useRefreshProfilePageStore } from 'store/global/refresh-page'
import { useDeviceStore } from 'store/server-context/device'
import { normalizeAsset } from 'util/normalizeAsset'
import { fetchUserSpots } from './profilePostList/data'
import SuggestedPostList from './profilePostList/suggestedPostList'

interface IUserSpots {
  userId: string
  userUid: string
  isMe: boolean
}
const UserSpots = (props: IUserSpots) => {
  const { userId, userUid, isMe } = props

  const locale = useLocale()
  const isOpen = usePopupStore(state => state.mask.download)
  const showPopup = usePopupStore(state => state.showDownloadPopup)
  const setInitialSpotIds = useInitialSpotIdsStore(
    state => state.setInitialSpotIds
  )
  const refreshingId = useRefreshProfilePageStore(state => state.refreshingId)
  const device = useDeviceStore(state => state.device)

  useScrollingCallback({
    callback: showPopup,
    scrollDistance: POPUP_TRIGGER_HEIGHT.post,
    enabled: device !== 'desktop' && !isOpen
  })
  const {
    data,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading: isInitialLoading
  } = useInfiniteQuery({
    queryKey: ['user/spots', userId, refreshingId],
    initialPageParam: '',
    queryFn: async ({ pageParam }) => {
      try {
        const { page_token, spot_list } = await fetchUserSpots(
          userId,
          locale,
          pageParam
        )
        return {
          spot_list,
          page_token
        }
      } catch (error) {
        console.log(error)
        return {
          spot_list: [],
          page_token: NO_MORE_DATA
        }
      }
    },
    select: data => data.pages.map(page => page.spot_list).flat(),
    getNextPageParam: lastPage => {
      if (
        lastPage.page_token === NO_MORE_DATA ||
        !lastPage.page_token ||
        !lastPage.spot_list.length
      ) {
        return undefined
      }
      return lastPage.page_token
    }
  })

  const spots = data || []

  const handleScroll = () => {
    fetchNextPage()
  }

  const handleOpenVideo = (index: number) => {
    const spotListAfterIndex = spots.slice(index + 1)
    setInitialSpotIds(spotListAfterIndex.map(spot => spot.id).join(','))
  }

  const noMoreData = !isInitialLoading && !hasNextPage

  return (
    <div>
      <ScrollLoad
        callback={handleScroll}
        fetching={isFetchingNextPage || isInitialLoading}
        fetchEnd={noMoreData}
      >
        <div className='mt-[1px] min768:my-[12px]'>
          {spots.length === 0 && !isInitialLoading ? (
            isMe ? (
              <NoMySpot onStartClick={showPopup} className='mt-16' />
            ) : (
              <NoSpot uid={userUid} className='mt-[24px]' />
            )
          ) : (
            <div className='relative mx-auto w-full'>
              <div className='grid w-full grid-cols-3 gap-[1px] min768:gap-[12px]'>
                {spots.map((spot, index) => {
                  const { src } = normalizeAsset(spot.asset)
                  return (
                    <SpotLink
                      key={spot.id}
                      spotId={spot.id}
                      coverUrl={src}
                      desc={spot.description}
                      locationName={spot.place?.name || ''}
                      isProfileUserReposted={spot.is_profile_user_reposted}
                      counts={spot.counts}
                      onClick={() => handleOpenVideo(index)}
                    />
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </ScrollLoad>
      {!isMe && !isInitialLoading && noMoreData && (
        <SuggestedPostList userId={userId} />
      )}
    </div>
  )
}

export default UserSpots
