import { getUserSpotsV2 } from 'api/user'
import { NO_MORE_DATA, SPOT_SIZE } from 'constant'
import { clientFetch } from 'lib/fetch/client'
import { fetchSpots } from 'server-actions/fetch-spots'
import type { ISpotV2WithFullContent } from 'type/spot'

export const fetchUserSpots = async (
  userId: string,
  locale: string,
  pageToken?: string
) => {
  const { data } = await getUserSpotsV2(
    clientFetch,
    userId,
    `size=${SPOT_SIZE}` + (pageToken ? `&page_token=${pageToken}` : '')
  )

  if (data.spots.length === 0) {
    return {
      spot_list: [],
      page_token: NO_MORE_DATA
    }
  }

  const spots = await fetchSpots(data.spots, locale)

  return {
    spot_list: spots,
    page_token: data.next_page_token || NO_MORE_DATA
  }
}
