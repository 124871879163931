import { useTranslations } from 'next-intl'
import { type ComponentPropsWithoutRef } from 'react'
import cn from 'util/cn'

interface INoActivity extends ComponentPropsWithoutRef<'div'> {
  uid?: string
}

const NoActivity = ({ uid, ...props }: INoActivity) => {
  const t = useTranslations()

  return (
    <div
      {...props}
      className={cn(
        'flex flex-col items-center justify-center',
        props.className
      )}
    >
      <p className='text-md text-label-l2'>
        {uid
          ? t('no_activities_description_of_other_profile', { user: uid })
          : t('board.no_comments_yet')}
      </p>
    </div>
  )
}

export default NoActivity
