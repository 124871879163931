'use client'

import Comment from 'component/block/comment'
import CommentTitle from 'component/block/comment/comment-title'
import Content from 'component/block/comment/content'
import InteractiveTools from 'component/block/comment/interactive-tools'
import Link from 'next/link'
import NoActivity from 'component/block/no-content/activity'
import ScrollLoad from 'component/block/scrollLoad'
import uniqueArray from 'util/uniqueArray'
import useScrollingCallback from 'hook/useScrollingCallback'
import { MediaType, type IMediaV2 } from 'type/media'
import { POPUP_TRIGGER_HEIGHT } from 'constant'
import { clientFetch } from 'lib/fetch/client'
import { getAssetsV2 } from 'api/asset'
import { getCommentActivitiesV1 } from 'api/comment'
import { getCommunitiesByIds } from 'api/community'
import { type IAssetV2 } from 'type/asset'
import { useDeviceStore } from 'store/server-context/device'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useLocale } from 'next-intl'
import { usePopupStore } from 'store/global/popup'
import { useRefreshProfilePageStore } from 'store/global/refresh-page'
import NoMyActivity from 'component/block/no-content/my-activity'
import { fetchCommentsDetail } from 'server-actions/fetch-comments'

interface IUserSpots {
  userId: string
  userUid: string
  userName: string
  headshot: IAssetV2 | undefined | null
  isMe: boolean
}

const SIZE = 10

const UserActivities = (props: IUserSpots) => {
  const { headshot, userId, userUid, userName, isMe } = props
  const device = useDeviceStore(state => state.device)
  const isOpen = usePopupStore(state => state.mask.download)
  const locale = useLocale()
  const refreshingId = useRefreshProfilePageStore(state => state.refreshingId)
  const showPopup = usePopupStore(state => state.showDownloadPopup)

  const user = {
    user_id: userId,
    uid: userUid,
    name: userName,
    headshot
  }

  const {
    data: { comments = [] } = {},
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading: isInitialLoading,
    isFetching
  } = useInfiniteQuery({
    queryKey: ['user/activities', userId, refreshingId],
    initialPageParam: '',
    queryFn: async ({ pageParam }) => {
      try {
        const searchParams = new URLSearchParams({
          user_id: userId,
          size: String(SIZE),
          page_token: pageParam,
          lang: locale
        })

        const { data } = await getCommentActivitiesV1(clientFetch, searchParams)

        if (data.comments.length === 0) {
          throw null
        }

        const comments = await fetchCommentsDetail(data.comments, locale)

        return {
          comments: comments,
          pageToken: data.next_page_token || undefined
        }
      } catch (error) {
        return {
          comments: [],
          pageToken: undefined
        }
      }
    },
    select(data) {
      return {
        comments: data.pages.flatMap(item => item.comments)
      }
    },
    getNextPageParam: lastPage => {
      return lastPage.pageToken
    }
  })

  const handleScroll = () => {
    fetchNextPage()
  }

  useScrollingCallback({
    callback: showPopup,
    scrollDistance: POPUP_TRIGGER_HEIGHT.post,
    enabled: device !== 'desktop' && !isOpen
  })

  const noMoreData = !isInitialLoading && !hasNextPage

  return (
    <>
      <div>
        {comments?.length === 0 && !isInitialLoading ? (
          isMe ? (
            <NoMyActivity onStartClick={showPopup} className='mt-16' />
          ) : (
            <NoActivity uid={userUid} className='mt-[24px]' />
          )
        ) : (
          <div className='relative mx-auto w-full'>
            <ScrollLoad
              callback={handleScroll}
              fetching={isInitialLoading || isFetchingNextPage || isFetching}
              fetchEnd={noMoreData}
            >
              {comments.map(comment => {
                const { comment: main, subComments = [] } = comment

                const commentId = main.id || main.comment_id || ''

                return (
                  <Link
                    key={main.id}
                    href={`/community/${main.parent_id}/comment/${main.id}`}
                    className='block border-b-[2px] border-glass_special-separators pb-[16px]'
                  >
                    <Comment
                      title={
                        main?.parent_title && (
                          <CommentTitle
                            communityId={main.parent_id || ''}
                            title={main?.parent_title || ''}
                          />
                        )
                      }
                      className='pt-[16px]'
                      user={main.user}
                    >
                      <Content
                        comment={main.text}
                        user={main.user}
                        commentTime={main.created_at}
                        media={main.media!}
                        interactiveTools={
                          <InteractiveTools
                            className='mt-2'
                            commentId={commentId}
                            isLiked={main.personal?.is_liked}
                            likeCount={main.counts.like}
                            shareCount={main.counts.share}
                            replyCount={0}
                          />
                        }
                      />
                    </Comment>
                  </Link>
                )
              })}
            </ScrollLoad>
          </div>
        )}
      </div>
    </>
  )
}

export default UserActivities
