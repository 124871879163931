import 'linkify-plugin-hashtag'
import 'lib/linkify-plugin-mention'
import Linkify from 'linkify-react'
import { type Opts } from 'linkifyjs'
import { Fragment, useMemo } from 'react'

import Link from 'component/block/link'

interface IParsedText {
  text: string
  parseHashtag?: boolean
  parseMention?: boolean
  parseHyperlink?: boolean
}
const LinkifyText = (props: IParsedText) => {
  const { text, parseHashtag, parseMention, parseHyperlink } = props

  const options = useMemo(() => {
    return {
      ignoreTags: ['script', 'style'],
      render: {
        hashtag: ({ content }) => {
          if (parseHashtag) {
            return (
              <Link
                title={content}
                href={`/hashtag/${content.substring(1)}`}
                className='text-md font-semibold text-label-l1'
              >
                {content}
              </Link>
            )
          }
          return <Fragment>{content}</Fragment>
        },
        mention: ({ content }) => {
          if (parseMention) {
            return (
              <Link
                title={content}
                href={`/${content.substring(1)}`}
                className='text-md font-semibold text-label-l1'
              >
                {content}
              </Link>
            )
          }
          return <Fragment>{content}</Fragment>
        },
        format: () => {},
        url: ({ content, attributes }) => {
          if (parseHyperlink) {
            return (
              <a
                target='_blank'
                rel='noreferrer noopener nofollow'
                title={content}
                href={attributes.href}
                className='text-md text-secondary-p1'
              >
                {content}
              </a>
            )
          }
          return <Fragment>{content}</Fragment>
        }
      }
    } satisfies Opts
  }, [parseHashtag, parseHyperlink, parseMention])

  return <Linkify options={options}>{text}</Linkify>
}

export default LinkifyText
