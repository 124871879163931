'use client'

import React from 'react'
import cn from 'util/cn'
import Link from '../link'

interface ICommentTitleProps {
  title: string
  className?: string
  communityId: string
}

const CommentTitle = (props: ICommentTitleProps) => {
  return (
    <Link
      href={`/community/${props.communityId}`}
      className={cn(
        'flex items-center bg-background-1st pl-[16px] text-md font-semibold text-label-l2 mb-[4px]',
        props.className
      )}
      onClick={e => e.stopPropagation()}
    >
      <div
        className='i-ps-group_f [--icon-size:14px] shrink-0 mr-[2px]'
        role='img'
        aria-label='Community'
      />
      <span className='min-w-0 truncate max-w-[--content-size]'>
        {props.title}
      </span>
    </Link>
  )
}

export default CommentTitle
