'use client'

import { create } from 'zustand'

export interface InitialSpotIds {
  initialSpotIds: string
}

export interface InitialSpotIdsAction {
  setInitialSpotIds: (spotIds: InitialSpotIds['initialSpotIds']) => void
}

export const createInitialSpotIdsStore = () =>
  create<InitialSpotIds & InitialSpotIdsAction>(set => ({
    initialSpotIds: '',
    setInitialSpotIds: spotIds => set({ initialSpotIds: spotIds })
  }))

export const useInitialSpotIdsStore = createInitialSpotIdsStore()
