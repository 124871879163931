'use client'

import { useInfiniteQuery } from '@tanstack/react-query'
import ScrollLoad from 'component/block/scrollLoad'
import SpotLink from 'component/block/spot/spot-link'
import { NO_MORE_DATA } from 'constant'
import { useTranslations } from 'next-intl'
import { useRefreshProfilePageStore } from 'store/global/refresh-page'
import cn from 'util/cn'
import { getProfileSuggestedSpot } from 'api/profile'
import { clientFetch } from 'lib/fetch/client'
import { useSessionStore } from 'store/server-context/session'
import { normalizeAsset } from 'util/normalizeAsset'

const SIZE = 12
interface ISuggestedPostList {
  userId: string
}
const SuggestedPostList = (props: ISuggestedPostList) => {
  const { userId: profileUserId } = props
  const session = useSessionStore(state => state.session)

  const refreshingId = useRefreshProfilePageStore(state => state.refreshingId)
  const t = useTranslations()
  const {
    data: { spots = [] } = {},
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading: isInitialLoading
  } = useInfiniteQuery({
    queryKey: ['user/spots/suggested', profileUserId, refreshingId],
    initialPageParam: '',
    queryFn: async ({ pageParam }) => {
      try {
        const searchParams = new URLSearchParams({
          user_id: session.user_id,
          profile_user_id: profileUserId,
          size: String(SIZE),
          page_token: pageParam
        })

        const { data: { spots = [] } = {}, trace_id } =
          await getProfileSuggestedSpot(clientFetch, searchParams)

        if (spots.length === 0) {
          throw null
        }

        return {
          spots,
          page_token: trace_id
        }
      } catch (error) {
        return {
          spots: [],
          page_token: undefined
        }
      }
    },
    select(data) {
      return {
        spots: data.pages.flatMap(item => item.spots)
      }
    },
    getNextPageParam: lastPage => {
      return lastPage.page_token
    }
  })

  const handleScroll = () => {
    fetchNextPage()
  }

  return (
    <div className='mt-[24px]'>
      <div
        className={cn(
          'border-t border-glass_special-separators bg-background-1st text-lg font-bold text-label-l1',
          'm-0 px-[16px] py-[12px]',
          `sticky top-[112px] z-10`
        )}
      >
        {t('common.suggested_for_you')}
      </div>
      <ScrollLoad
        className='w-full'
        callback={handleScroll}
        fetching={isInitialLoading || isFetchingNextPage}
        fetchEnd={!isInitialLoading && !hasNextPage}
      >
        <div className='relative mx-auto w-full'>
          <div className='grid w-full grid-cols-3 gap-[1px] min768:gap-[12px]'>
            {spots.map(post => {
              const { src } = normalizeAsset(post?.asset)

              return (
                <SpotLink
                  key={post?.id}
                  spotId={post?.id}
                  coverUrl={src}
                  desc={post.description}
                  counts={post.counts}
                  locationName={post.place.location.display_name}
                />
              )
            })}
          </div>
        </div>
      </ScrollLoad>
    </div>
  )
}

export default SuggestedPostList
