import { useTranslations } from 'next-intl'
import { type ComponentPropsWithoutRef } from 'react'

import IconSend from 'asset/icon/send.svg'
import cn from 'util/cn'

interface INoMyActivity extends ComponentPropsWithoutRef<'div'> {
  onStartClick?: () => void
}

const NoMyActivity = ({ onStartClick, ...props }: INoMyActivity) => {
  const t = useTranslations()

  return (
    <div
      {...props}
      className={cn(
        'flex flex-col items-center justify-center',
        props.className
      )}
    >
      <div className='mb-[16px] flex h-[88px] w-[88px] items-center justify-center rounded-full bg-playseeGray-gray4'>
        <IconSend width={60} height={60} className='fill-white' />
      </div>
      <h3 className='m-0 mb-[4px] p-0 text-md font-semibold text-label-l1'>
        {t('board.no_comments_yet')}
      </h3>
      <p className='text-md text-label-l2'>
        {t('no_activities_description_of_my_profile')}
      </p>
      <span
        className='mt-[16px] block cursor-pointer text-md font-semibold text-primary-p1'
        onClick={onStartClick}
      >
        {t('write_first_comment')}
      </span>
    </div>
  )
}

export default NoMyActivity
