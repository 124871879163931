import { useTranslations } from 'next-intl'
import { type ComponentPropsWithoutRef } from 'react'

import IconVideo from 'asset/icon/video.svg'
import cn from 'util/cn'

interface INoMySpot extends ComponentPropsWithoutRef<'div'> {
  onStartClick?: () => void
}
const NoMySpot = ({ onStartClick, ...props }: INoMySpot) => {
  const t = useTranslations()

  return (
    <div
      {...props}
      className={cn(
        'flex flex-col items-center justify-center',
        props.className
      )}
    >
      <div className='mb-[16px] flex h-[88px] w-[88px] items-center justify-center rounded-full bg-playseeGray-gray4'>
        <IconVideo width={60} height={60} className='fill-white' />
      </div>
      <h3 className='m-0 mb-[4px] p-0 text-md font-semibold text-label-l1'>
        {t('common.spot')}
      </h3>
      <p className='text-md text-label-l2'>{t('profile.me.no_posts')}</p>
      <span
        className='mt-[16px] block cursor-pointer text-md font-semibold text-primary-p1'
        onClick={onStartClick}
      >
        {t('profile.me.start_post')}
      </span>
    </div>
  )
}

export default NoMySpot
