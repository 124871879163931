import { useTranslations } from 'next-intl'
import { type ComponentPropsWithoutRef } from 'react'

import cn from 'util/cn'

interface INoSpot extends ComponentPropsWithoutRef<'div'> {
  uid?: string
}
const NoSpot = ({ uid, ...props }: INoSpot) => {
  const t = useTranslations()

  return (
    <div
      {...props}
      className={cn(
        'flex flex-col items-center justify-center',
        props.className
      )}
    >
      <p className='text-md text-label-l2'>
        {uid
          ? t('profile.no_spots_yet', { username: uid })
          : t('common.no_videos_yet')}
      </p>
    </div>
  )
}

export default NoSpot
