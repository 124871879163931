'use client'

import React from 'react'

import IconPin from 'asset/icon/geo/pin_f.svg'
import IconRepost from 'asset/icon/repost_s.svg'
import IconVideo from 'asset/icon/video.svg'
import { Image } from 'component/ui/image'
import Link from 'component/block/link'
import numberFormat from 'util/numberFormat'

interface ISpotLink {
  coverUrl: string
  locationName?: string
  desc: string
  spotId: string
  isProfileUserReposted?: boolean
  counts?: {
    likes?: number
    replies?: number
    views?: number
    shares?: number
  }
  onClick?: (e: React.MouseEvent) => void
}
const SpotLink = (props: ISpotLink) => {
  const { coverUrl, desc, locationName, spotId, counts, onClick } = props

  return (
    <Link
      href={`/post/${spotId}`}
      className='relative flex bg-background-b1 before:block before:w-full before:pt-[150%]'
      title={locationName}
      onClick={onClick}
    >
      <Image
        src={coverUrl}
        alt={desc}
        fill={true}
        loading='lazy'
        fallback={<div />}
      />

      <div className='absolute top-0 left-0 w-full flex flex-col justify-end'>
        <div className='flex w-full items-start justify-end self-end px-[6px] py-[3px] text-label_still-l1'>
          {props.isProfileUserReposted && (
            <IconRepost className='mr-[4px] sq-[24px] flex-shrink-0' />
          )}
        </div>
      </div>
      <div className='absolute bottom-0 left-0 h-1/4 w-full flex flex-col justify-end bg-gradient-post'>
        <div className='flex w-full items-start self-end px-[6px] py-[3px] text-label_still-l1'>
          <IconVideo className='mr-[4px] h-[14px] w-[14px] flex-shrink-0' />
          <span className='line-clamp-2 break-words text-sm font-semibold'>
            {numberFormat(counts?.views || 0, 'compact')}
          </span>
        </div>
        {locationName && (
          <div className='flex w-full items-start self-end px-[6px] py-[3px] text-label_still-l1'>
            <IconPin className='mr-[4px] h-[14px] w-[14px] flex-shrink-0' />
            <span className='line-clamp-2 break-words text-sm font-semibold'>
              {locationName}
            </span>
          </div>
        )}
      </div>
    </Link>
  )
}

export default SpotLink
